import { ReactNode } from "react";
import { ActionMap } from "../library/ActionMap";

export interface State {
    userProfile: UserProfile;
    animeImage: string;
}
export type Actions = ActionMap<ActionTypes, Payloads>;

export enum ActionTypes {
    SUBMIT_ANIME = "SUBMIT_ANIME",
    SUBMIT_LOGIN = "SUBMIT_LOGIN"
  }

export type Payloads = {
    [ActionTypes.SUBMIT_ANIME]: AnimeImageState;
    [ActionTypes.SUBMIT_LOGIN]: LoginState;
}
export interface AnimeImageState {
    animeImage: string;
  }
export interface LoginState {
  userProfile: UserProfile;
  }
export type ScreenProps = {
  mainTitle: string;
  subTitle: string;
}
export interface ImageProps {
  type: string;
  progressBar: boolean;
  alt?:string;
  progress?: number;
  hasError?: boolean;
  imageState?: string;
  onClose?: (param?:any)=>void;
}
export interface ImageButton {
  children?: ReactNode;
  type?: string;
  position?: string;
  btnClassName?: string;
  ref? : React.MutableRefObject<any>;
  disabled?: boolean;
  onClick: (event?: any)=>void;
}
export type UserProfile = {
  aud: string;
  azd: string
  name: string;
  email: string;
  picture: string;
  userPoint: number;
  isLoggingIn: boolean;
}