import { ActionTypes, Actions, State } from "../constant/types";


export const cliReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.SUBMIT_ANIME:
      {
      const { type: _, ...payload } = action;
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};
