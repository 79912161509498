import { ReactNode, useMemo, useReducer } from "react";
import { cliReducer } from "../reducer/reducer";
import { initialState } from "../reducer/initialState";
import { CliContext } from "../reducer/context";
import "../styles/styles.css";

type Props = {
  children: ReactNode;
};

export const AutoRigProvider = ({ children }: Props) => {
  const [cliPagesState, dispatch] = useReducer(cliReducer, initialState);
  const contextValue = useMemo(
    () => ({ state: cliPagesState, dispatch }),
    [cliPagesState, dispatch],
  );
  return (
    <CliContext.Provider value={contextValue}>{children}</CliContext.Provider>
  );
};
