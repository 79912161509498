import { useTranslation } from "react-i18next";
import { BANNER_IMGS, TOP_IMG_POSITION } from "../../constant/constant";

const BannerComPonent = () => {
    const { t: translate } = useTranslation();
    return (
        <div className="banner display-flex">
            <div className="banner-slogan">
                <h1>{translate("bannerTitle")}</h1>
                <p className="slogan1">{translate("bannerTitle1")}</p>
                <p className="slogan2">{translate("bannerTitle2")}</p>
                <button className="banner-btn" onClick={() => { window.scroll({ top: TOP_IMG_POSITION, left: 0, behavior: 'smooth' }) }}><p>{translate("getStartedForFree")}</p></button>
            </div>
            <div className="banner-img display-flex">
                {BANNER_IMGS.map((item, index)=>{
                    return (
                        <img key={`banner-img-${index+1}`} src={item.img_src} alt={item.img_alt} />
                    )
                })}
            </div>
        </div>
    )
}

export default BannerComPonent;