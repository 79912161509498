import { AiFillCloseCircle } from 'react-icons/ai';
import { IMAGE_TYPE } from "../../constant/constant";
import 'react-circular-progressbar/dist/styles.css';
import { ImageButton, ImageProps } from "../../constant/types";
import AutorigButton from "../ButtonComponent/ButtonComponent";
import CircleProgressBar from '../ProgressBar/CircleProgressBar';

const ImageComponent = (props: ImageProps) => {
    const { type:imageType = "",progressBar =false,progress=0,imageState="",alt:imageAlt="", onClose=()=>{}, hasError = false } = props;
    const closeBtnProps: ImageButton = {
      type: 'CLOSE',
      btnClassName: 'btn-close',
      onClick : onClose
    }
    return (
        <div className='container display-flex'>
          {!imageState && (<div className="img-holder container">
          <CircleProgressBar progress={progress} hasError={hasError} isShow={progressBar && !!progress && progress > 0} ></CircleProgressBar>
          </div>)}
          {imageState && (
            <div className="img-display container">
              {imageType && imageType === IMAGE_TYPE.UPLOAD && <AutorigButton {...closeBtnProps}><AiFillCloseCircle size={40} /></AutorigButton>}
              <img
                alt={imageAlt}
                src={imageState}
              />
              <br />
            </div>
          )}
        </div>
      );
};

export default ImageComponent;