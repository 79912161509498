import 'react-circular-progressbar/dist/styles.css';
import { TRIAL_IMGS_LOCAL } from '../../constant/constant';
import { useTranslation } from 'react-i18next';

const TrialImageComponent = (props) => {
    const { t: translate } = useTranslation();
    const { onClick } = props;
    const fetchImgAsFile = async (imgUrl) => {
        try {
            const response = await fetch(imgUrl, {mode: "no-cors",headers:{'Access-Control-Allow-Origin': '*',}});
            const blob = await response.blob();
            const file = new File([blob],'fetchedImg',{ type: "image/jpeg"});
            onClick(file);
        }
        catch (error) {
            console.error("Err fetching img:", error)
        }
    }
    return (
        <div className='container display-flex-col'>
            {/* <p className='trial-text'>-or-</p> */}
            <div className='container trial-img-container'>
            <p className='no-img-text' dangerouslySetInnerHTML={{ __html: translate("noImg")}}></p>
            <div className='trial-img display-flex'>
                {TRIAL_IMGS_LOCAL.map((item, index) => {
                    return (
                        <img key={`trial-img-${index + 1}`} src={item.img_src} alt={item.img_alt} onClick={()=>{fetchImgAsFile(item.img_src)}} loading='lazy' />
                    )
                })}
            </div>
        </div>
        </div>
    );
};

export default TrialImageComponent;