import { State } from "../constant/types";


export const initialState: State = {
  userProfile: {
    aud: "",
    azd: "",
    name: "",
    email: "",
    picture: "",
    userPoint: 0,
  isLoggingIn: false
  },
  animeImage: "",
};
