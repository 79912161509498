// importing material UI components

import { ScreenProps } from "../../constant/types";
import i18n from "i18next";
import vnFlag from "../../assets/images/vn-flag.png";
import usFlag from "../../assets/images/us-flag.png";
import { useTranslation } from "react-i18next";
import { HEADER_LOGO } from "../../constant/constant";

function Header({ mainTitle, subTitle }: ScreenProps) {
  // const {
  //   state,
  //   dispatch,
  // } = useCliContext();
  // const onLogin = (userInfo: any) => {
  //   var credentialResponseDecoded: UserProfile = jwt_decode(userInfo.credential || "");
  //   if (credentialResponseDecoded.aud) {
  //     dispatch({
  //       type: ActionTypes.SUBMIT_LOGIN,
  //       userProfile: credentialResponseDecoded
  //     });
  //   }
  // }
  const onChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }
  const { t:translate } = useTranslation();
  return (
    // <AppBar position="static">
    //   <Toolbar className="header">
    //     {/*Inside the IconButton, we 
    //        can render various icons*/}

    //     {/* The Typography component applies 
    //        default font weights and sizes */}

    //     <Typography variant="h6"
    //       component="div" sx={{ flexGrow: 1 }}>
    //       My Anime Pictures
    //     </Typography>
    //     {/* <Button color="inherit">Login</Button> */}
    //     {/* <div>
    //       <GoogleLogin
    //         onSuccess={credentialResponse => {
    //           onLogin(credentialResponse);
    //         }}
    //         onError={() => {
    //           console.log('Login Failed');
    //         }}
    //       />
    //       <LoginSocialFacebook
    //         appId="283954181115281"
    //         onResolve={(response) => console.log(response)}
    //         onReject={(err) => console.log(err)}>
    //         <FacebookLoginButton></FacebookLoginButton>
    //       </LoginSocialFacebook>
    //     </div> */}
    //   </Toolbar>
    // </AppBar>
    <header className="landing-header">
      <div className="header">
        <img src={HEADER_LOGO} className="header-logo" alt="autorig-avt" />
        <div className="title-containner">
        <div className="title">
          <h1 className="main-title">{mainTitle}</h1>
          <h2 className="sub-title">{subTitle}</h2>
        </div>
        <p className="slogan">{translate('slogan')}</p>
        </div>
        <div className="language">
          <button className="lang-btn" onClick={() => { onChangeLanguage('en') }}>
            <img src={usFlag} className="flag" alt="english" />
            <span>ENG</span>
          </button>/<button className="lang-btn" onClick={() => { onChangeLanguage('vi') }}>
            <img src={vnFlag} className="flag" alt="vietnam" />
            <span>VIE</span>
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
