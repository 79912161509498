import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t: translate } = useTranslation();
    return (
        <footer className="container display-flex display-flex-col footer-container">
            <div className="term-privacy display-flex display-flex-row">
            {/* <a href="/blog" className="link">{translate("footerBlog")}</a>  */}
            <a href="/terms-of-use" className="link">{translate("footerTerm")}</a>
            <a href="/privacy-policy" className="link">{translate("footerPrivacy")}</a>
            </div>
            <hr />
            <div className="copy-right">
                Copyright © 2023 by <span className="author">Manga Reader</span>
            </div>
        </footer>
    )
};

export default Footer;