import { useEffect, useRef, useState } from "react";
import utf8 from 'utf8';
import { IMAGE_TYPE, api_url, api_path_request_job, api_path_token, token_params, api_path_request_job_progress, ErrorHttpStatusCode, JOB_PROGRESS_INTERVAL, FAKE_PROGRESS_INTERVAL, PAYLOAD_IMG_MODEL, QUEUE_JOB_PROGRESS_INTERVAL, TOP_IMG_POSITION } from "../../constant/constant";
import 'react-circular-progressbar/dist/styles.css';
import ImageComponent from "../ImageComponent/ImageComponent";
import AutorigButton from "../ButtonComponent/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { postHref } from "../../library/httpMethods";
import { AxiosError } from "axios";
import { getUrlParameter, resizeFile } from "../../utils/util";
import { useTranslation } from "react-i18next";
import QueueModal from "../Modal/QueueModal";
import LazyBackground from "./LazyBackground";
import { FileUploader } from "react-drag-drop-files";
import TrialImageComponent from "../ImageComponent/TrialImageComponent";

const ImageTransformation = () => {
	const [selectedImage, setSelectedImage] = useState(undefined);
	const [transformedImage, setTransformedImage] = useState(undefined);
	const [base64Img, setBase64Img] = useState(undefined);
	const [progress, setProgressBarValue] = useState(0);
	const [accessToken, setAccessToken] = useState('');
	const [isLoading, setLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [imgResult, setImgResult] = useState({});
	const [queue, setQueue] = useState(0);
	const [isModelOpen, setModelOpen] = useState(false);
	const interval = useRef<ReturnType<typeof setInterval> | undefined>(undefined)
	const { t: translate } = useTranslation();
	const navigate = useNavigate();
	const handleChange = async (event: any) => {
		try {
            const file = event.target?.files[0] || event;
			const image = await resizeFile(file);
			setSelectedImage(image);
			setBase64Img(image);
		} catch (err) {
			console.log(err);
		}
	};

	const handleOnImageRemove = () => {
		setSelectedImage(undefined);
		setBase64Img(undefined);
	};

	const handleTransfer = (base64Img: any) => {
		setProgressBarValue(0);
		setHasError(false);
		interval.current = undefined;
		if (base64Img) {
			const encode_image_utf8 = utf8.encode(base64Img);
			const jobuuid = uuidv4();
			setTransformedImage(undefined);
			if (accessToken) {
				requestGeningImg(jobuuid, encode_image_utf8);
			} else {
				getRequestToken(jobuuid, encode_image_utf8);
			}
		}
	}
	const handleFakeProgress = () => {
		let fakeProgress = 0;
		interval.current = setInterval(() => {
			if (fakeProgress === 99) {
				clearInterval(interval.current);
				fakeProgress = 0;
			} else {
				fakeProgress++;
				setProgressBarValue(fakeProgress);
			}
		}, FAKE_PROGRESS_INTERVAL);
	}

	const requestGeningImg = (jobId: string, img: string, token: string = "") => {
		const requestJobUrl = api_url + api_path_request_job;
		const requestJobProgress = api_url + api_path_request_job_progress;
		const jobPayload = {
			job_id: jobId,
			type: PAYLOAD_IMG_MODEL,
			img64: img
		};
		const jobProgressPayload = {
			job_id: jobId
		}
		const requestHeaders = { Authorization: `Bearer ${token || accessToken}`, };
		setLoading(true);

		postHref(requestJobUrl, JSON.stringify(jobPayload, null, 2), requestHeaders).then((res: any) => {
			if (res.data) {
				if (res.data.error) {
					handleError(res.data.error, jobId, img);
					return;
				}
				if (res.data.num_waiting_job > 0) {
					setQueue(res.data.num_waiting_job);
					handleOpenModel();
					let genQueue = res.data.num_waiting_job;
					let countDownQueue = setInterval(() => {
						genQueue--;
						setQueue(genQueue);
						if (genQueue === 0) {
							handleJobProgressRequest(requestJobProgress, jobProgressPayload, requestHeaders, jobId, img);
							clearInterval(countDownQueue);
						}
					}, QUEUE_JOB_PROGRESS_INTERVAL);
				} else {
					handleJobProgressRequest(requestJobProgress, jobProgressPayload, requestHeaders, jobId, img);
				}
			}
		}).catch((err: AxiosError) => {
			handleError(err, jobId, img);
		})
	}

	const handleJobProgressRequest = (requestJobProgress, jobProgressPayload, requestHeaders, jobId, img) => {
		if (!interval.current) {
			handleFakeProgress();
		}
		let jobProgressRequest = setInterval(() => {
			postHref(requestJobProgress, JSON.stringify(jobProgressPayload, null, 2), requestHeaders).then((res: any) => {
				if (res.data) {
					if (res.data.error) {
						handleError(res.data.error, jobId, img);
						clearInterval(jobProgressRequest);
						return;
					}
					const { progress } = res.data;
					if (progress && progress < 100) {
						//setProgressBarValue(progress);
					} else if (progress === 100) {
						const { url_anime, url_origin, url_concated, url_share_page } = res.data;
						const responseTransformedImg = "https://" + url_anime;
						setTransformedImage(responseTransformedImg as any);
						setImgResult({ url_anime, url_origin, url_concated, url_share_page });
						setLoading(false);
						clearInterval(jobProgressRequest);
					}
				}
			}).catch((err: AxiosError) => {
				clearInterval(jobProgressRequest);
				handleError(err, jobId, img);
			})
		}, JOB_PROGRESS_INTERVAL);
	}

	const getRequestToken = (jobId: string, img: string) => {
		const reuestTokenUrl = api_url + api_path_token;
		setLoading(true);
		postHref(reuestTokenUrl, JSON.stringify(token_params, null, 2)).then((res: any) => {
			if (res.data) {
				if (res.data.error) {
					handleError(res.data.error, jobId, img);
					return;
				}
				const token = res.data["access_token"];
				setAccessToken(token as any);
				localStorage.setItem('access_token', token);
				requestGeningImg(jobId, img, token);
			}
		}).catch(err => { handleError(err, jobId, img); })
	}

	const handleError = (err: AxiosError, jobId: string, img: string) => {
		switch (err.response?.status) {
			case ErrorHttpStatusCode.NUMBER_401:
				//popup ads display handling
				getRequestToken(jobId, img);
				break;
			default:
				setProgressBarValue(0);
				setLoading(false);
				setHasError(true);
				break;
		}
		console.log(err);
	}

	useEffect(() => {
		const token = localStorage.getItem('access_token');
		if (token) {
			setAccessToken(token);
		}
		if (transformedImage && interval.current) {
			clearInterval(interval.current);
			setProgressBarValue(100);
		}
	}, [transformedImage, setProgressBarValue])

	const UploadImageComponent = () => {
        const fileTypes = ["JPG", "PNG"];
		return (
			<div className="container display-flex-col image-container">  
				{selectedImage && (<ImageComponent type={IMAGE_TYPE.UPLOAD} progressBar={false} alt={"Your Upload Photo"} imageState={selectedImage} onClose={handleOnImageRemove} />)}
                {!selectedImage && (<FileUploader handleChange={handleChange} fileTypes={fileTypes} name="file" classes="drag-drop-img img-holder container" />)}
                <AutorigButton type={IMAGE_TYPE.UPLOAD} btnClassName="btn-primary" onClick={handleChange}>{translate("selectImg")}</AutorigButton>
                <TrialImageComponent onClick={handleChange}/>
			</div>
		);
	}
	const TransformedImageComponent = () => {
		return (
			<div className="container display-flex-col image-container">
				<ImageComponent type={IMAGE_TYPE.TRANSFORM} progressBar={!isModelOpen} progress={progress} hasError={hasError} alt="Your Anime Photo" imageState={transformedImage} />
				<div className="container display-flex transform-btn-container">
                <AutorigButton type={IMAGE_TYPE.NAVIGATE} btnClassName="btn-primary" disabled={!transformedImage} onClick={() => { navigate(getUrlParameter(transformedImage), { state: imgResult }); }}>{translate("shareAnimePhoto")}</AutorigButton>
                <div className="container display-flex display-flex-col">
				<a className={`btn btn-primary ${!transformedImage ? "btn-disabled" : ""}`} href={transformedImage} target="_blank" download rel="noopener noreferrer">{translate("downloadAnimePhoto")}</a>
			</div>
                </div>
			</div>
		);
	}
	const handleOpenModel = () => {
		setModelOpen(!isModelOpen);
		window.scroll({ top: TOP_IMG_POSITION, left: 0, behavior: 'smooth' });
	}

	return (
		<div>
			<QueueModal isModelOpen={isModelOpen} queue={queue} onRequestClose={handleOpenModel} progress={progress} hasError={hasError}></QueueModal>
			<LazyBackground>
				<main>
					<div className="container display-flex display-flex-col">
						<div className="display-flex display-flex-row main-container">
							<UploadImageComponent />
							<div className="container genbtn">
								<label className={base64Img && !isLoading ? "btn btn-transfer" : "btn btn-transfer-disabled"} onClick={() => handleTransfer(base64Img)}>
									{isLoading ? translate("generating") : translate("generate")}
								</label>
							</div>
							<TransformedImageComponent />
						</div>
					</div>
				</main>
			</LazyBackground>
		</div>
	);
};

export default ImageTransformation;